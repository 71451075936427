const theme = {
  color: {
    textDark: '#140d38',
    textSecondary: '#9591a5',
    backgroundWhite: '#fff',
    backgroundLight: '#d8d8d833',
    backgroundDark: '#140d38',
  },
  font: {
    title: 'CircularStd',
    subtitle: 'CircularStdBook',
    body: 'Helvetica',
  },
};

export default theme;
