import styled from 'styled-components';

import { utils } from '@enso/components';
import theme from '../../../theme';

const ContentContainer = styled.div`
  padding: 24px;
  background-color: ${props => props.theme.color.backgroundWhite};

  @media ${utils.device.laptop} {
    padding-top: 79px;
    padding-bottom: 0;
    padding-left: 140px;
    padding-right: 140px;
    margin-bottom: -107px;
  }
`;

ContentContainer.defaultProps = { theme };

export default ContentContainer;
